import React, { useState, useEffect } from 'react';
import './App.css';
import 'tailwindcss/tailwind.css';
import axios from 'axios';
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

function App() {
  const [secretCode, setSecretCode] = useState('');
  const [students, setStudents] = useState([]);
  const [classCode, setClassCode] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  /**
 * Prevents the mobile browser behaviour that moves to the next or previous page
 * in your browser's history when you swipe in from the edge of the screen.
 *
 * Only seems to work reliably on Safari. Testing on Chrome iOS showed 
 * inconsistent effectiveness. Did not test other browsers.
 *
 * @returns A function to call to resume the browser's normal behaviour.
 */
  function preventBrowserHistorySwipeGestures() {
    function touchStart(ev) {
      if (ev.touches.length === 1) {
        const touch = ev.touches[0];
        if (
          touch.clientX < window.innerWidth * 0.1 ||
          touch.clientX > window.innerWidth * 0.9
        ) {
          ev.preventDefault();
        }
      }
    }

    // Safari defaults to passive: true for the touchstart event, so we need  to explicitly specify false
    // See https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
    const options = { passive: false };

    window.addEventListener("touchstart", touchStart, options);

    return () => window.removeEventListener("touchstart", touchStart, options);
  }

  // Prevent edge swipes
  preventBrowserHistorySwipeGestures();


  const handleCodeSubmit = async () => {
    // Fetch student list from backend for the given class code
    try {
      setIsLoading(true);
      const response = await axios.get(`https://tsw-attendance.azurewebsites.net/api/GetStudents?classCode=${secretCode}`);
      setStudents(response.data);
      setClassCode(secretCode);
      setAttendanceData(response.data.students.map(name => ({ name, status: '' })));
    } catch (error) {
      alert('Access denied.');
      console.error('Error fetching students:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSwipe = (studentName, status) => {
    setAttendanceData(attendanceData.map(student =>
      student.name === studentName ? { ...student, status } : student
    ));
  };

  const handleSaveRegister = async () => {
    // Send attendance data to backend for saving
    setIsLoading(true);
    try {
      await axios.post('https://tsw-attendance.azurewebsites.net/api/UpdateAttendanceBatch', {
        classCode: classCode,
        attendanceData: attendanceData,
      });
      alert('Attendance saved successfully!');
    } catch (error) {
      console.error('Error saving attendance:', error);
      alert('Failed to save attendance. Please try again. If the problem persists, please inform the office.');
    } finally {
      setIsLoading(false);
    }
  };

  const leadingActions = (studentName) => (
    <LeadingActions>
      <SwipeAction onClick={() => handleSwipe(studentName, 'a')} className="bg-red-500">
        <span className='p-4'>Absent</span>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (studentName) => (
    <TrailingActions>
      <SwipeAction onClick={() => handleSwipe(studentName, 'p')} className="bg-green-500">
        <span className='p-4'>Present</span>
      </SwipeAction>
    </TrailingActions>
  );

  function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 py-4">
      {!classCode ? (
        <div className="w-full max-w-md px-4">
                    <img src="/logo-thesunniway-lightbg.png"></img>
          <input
            type="text"
            placeholder="Enter Secret Code"
            value={secretCode}
            onChange={(e) => setSecretCode(e.target.value?.trim())}
            className="w-full p-2 mb-4 border rounded-lg"
          />
          <button
            onClick={handleCodeSubmit}
            className={`w-full py-2 px-4 rounded-lg text-white ${isLoading ? 'bg-gray-500' : 'bg-blue-500 hover:bg-green-600'
              }`}
            disabled={isLoading}
          >
            {isLoading ? 'Checking...' : 'Submit'}
          </button>
        </div>
      ) : (
        <div className="w-full max-w-md">
          <h2 className="text-xl font-semibold mb-2 px-4">TheSunniWay Madrasah Register</h2>
          <h3 className="text-md font-semibold mb-1 px-4">Class: {capitalizeFirstLetter(students?.className)} </h3>
          <h3 className="text-md mb-4 px-4">Marking attendance for {new Date().toDateString()} </h3>

          <SwipeableList threshold={0.1}>
            {attendanceData.map(student => {
              return (
                <SwipeableListItem
                  key={student.name}
                  leadingActions={leadingActions(student.name)}
                  trailingActions={trailingActions(student.name)}
                  threshold={0.1}
                >
                  <div
                    className={`flex justify-between items-center border-t border-b border-gray-400 py-5 w-full ${student.status === 'p'
                      ? 'bg-green-200'
                      : student.status === 'a'
                        ? 'bg-red-200'
                        : 'bg-gray-200'
                      }`}
                  >
                    <span className='px-4'>{student.name}</span>
                  </div>
                </SwipeableListItem>
              );
            })}
          </SwipeableList>
          <div className="flex justify-center w-full">
            <button
              onClick={handleSaveRegister}
              className={`w-3/4 mt-6 py-2 px-4 rounded-lg text-white ${isLoading ? 'bg-gray-500' : 'bg-green-500 hover:bg-green-600'
                }`}
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save Register'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
